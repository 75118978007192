<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  shopServiceMethods,
  notificationMethods
} from "@/state/helpers";

import {
  required,
  numeric,
  email
} from "vuelidate/lib/validators";

import { shopService } from '../../../services/shop.service';

const initForm = { name: "", phonenumber: "", email: "",location: "", createdBy: JSON.parse(localStorage.getItem('user')).id,};

export default {
  page: {
    title: "Shops ",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Branches",
      items: [
        {
          text: "Smart Exchange",
          href: "/"
        },
        {
          text: "Branches",
          active: true
        }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      action:"",
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      selectedShop:{},
      fields: [
        // { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "location", sortable: true },
        { key: "phoneNumber", sortable: true },
        { key: "emailAddress", sortable: true },
        { key: "actions"}
      ],

      form: {
        name: "",
        location: "",
        phoneNumber: null,
        emailAddress: "",
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },

      editForm: {
        id:null,
        name: null,
        location: null,
        phoneNumber: null,
        emailAddress: null,
        isActive:null,
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },

      submitted: false,
      submitform: false,
      submit: false,
      showform: false
    };
  },

  validations: {
    form: {
      name: { required },
      phoneNumber: { required, numeric },
      emailAddress: { required, email },
      location: { required }
    },
  },

  created() {
    this.loadAllShops()
  },

  computed: {
    rows() {
      return this.tableData.length;
    },
    notification() {
        return this.$store ? this.$store.state.notification : null;
      }
  },
  mounted() {
    this.totalRows = this.items.length;
  },

  methods: { 
    ...shopServiceMethods,
    ...notificationMethods,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.submitted = false;
        return;
      } else {
          const {form} = this;
          const shopForm = {
                "operation" : "shopCreation",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : form
          };
          
          shopService.registerShop(shopForm).then(result=>{
            if(result.status=="SUCCESS"){
              this.loadAllShops()
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.form = Object.assign({}, initForm);
            } else {
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      }
    },

    storeState(shop, action){
      this.editForm = shop.item;
      this.selectedShop = shop.item;
      this.action = action;
    },

    disableShop(shop, status){
      this.editForm = shop.item;
      this.editForm.isActive = !status;
      this.editShop();
    },

    editShop() {
      this.submitted = true;

          const {editForm} = this;
          const shopForm = {
                "operation" : "shopCreation",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : editForm
          };
          
          shopService.editShop(shopForm).then(result=>{
            if(result.status=="SUCCESS"){
              this.loadAllShops()
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.editForm = Object.assign({}, initForm);
            } else {
              this.submit = false;
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      
    },

    async loadAllShops() {
        try {
        await shopService.getAllShops().then(response=>{
            if(response.responseBody.length>0){
                this.tableData = response.responseBody;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },
  },

};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
                  <b-modal id="modal-standard" title="Edit Branch" title-class="font-18" hide-footer>
                      <div class="card-body">
                        <form class="needs-registration" @submit.prevent="editShop">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="validationCustom01">Branch Name</label>
                                <input
                                  id="validationCustom01"
                                  v-model="editForm.name"
                                  type="text"
                                  class="form-control"
                                  placeholder="Branch Name"
                                />
                              </div>
                            </div>
                            </div>
                            <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="validationCustom02">Branch Contact Number</label>
                                <div>
                                  <input
                                    id="validationCustom02"
                                    v-model="editForm.phoneNumber"
                                    type="number"
                                    class="form-control"
                                    placeholder="Enter phone number"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="validationCustom02">Branch Email address</label>
                                <div>
                                  <input
                                    id="validationCustom03"
                                    v-model="editForm.emailAddress"
                                    type="email"
                                    class="form-control"
                                    placeholder="Enter address "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="validationCustom04">Physical Address</label>
                                <input
                                  id="validationCustom03"
                                  v-model="editForm.location"
                                  type="text"
                                  class="form-control"
                                  placeholder="Physical Address"
                                />
                              </div>
                            </div>
                          </div>
                           <div class="spinner" v-if="submitted">
                                <i class="ri-loader-line spin-icon"></i>
                            </div>
                          <button class="btn btn-primary" type="submit">Save changes</button>
                        </form>
                      </div>
                  </b-modal>
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
          
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template #cell(actions)="row">
                <a
                  @click="storeState(row, 'edit')"
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >

                  <i v-b-modal.modal-standard class="mdi mdi-pencil font-size-18" ></i>

                </a>
                <a
                  v-if="row.item.isActive"
                  @click="disableShop(row, true)"
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Disable"
                >
                  <i class="mdi mdi-cancel font-size-18"></i>
                </a>

                <a
                v-if="!row.item.isActive"
                  @click="disableShop(row, false)"
                  href="javascript:void(0);"
                  class="text-success"
                  v-b-tooltip.hover
                  title="Enable Shop"
                >
                  <i class="mdi mdi-check font-size-18"></i>
                </a>

              </template>
      </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-5">
        <b-alert
          :variant="notificationType" 
          class="mt-3"
          v-if="notification.message"
          show
          dismissible
        >
          <i :class="'mdi '+ noticationIcon + ' mr-2'"></i>{{notification.message}}
        </b-alert>
          <a href="javascript:void(0);" class="btn btn-primary mb-2" @click="showform = true" v-if="!showform">
            <i class="mdi mdi-plus mr-2"></i> Add Branch
          </a>

        <div class="card" v-if="showform">
        <div class="card-header">
          <h5>Add New branch</h5>
        </div>
            <div class="card-body">
              
              <form class="needs-registration" @submit.prevent="formSubmit">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="validationCustom01">Branch Name</label>
                      <input
                        id="validationCustom01"
                        v-model="form.name"
                        type="text"
                        class="form-control"
                        placeholder="Branch Name"
                        :class="{ 'is-invalid': submitted && $v.form.name.$error }"
                      />
                      <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                        <span v-if="!$v.form.name.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="validationCustom02">Branch Contact Number</label>
                      <div>
                        <input
                          id="validationCustom02"
                          v-model="form.phoneNumber"
                          type="number"
                          class="form-control"
                          placeholder="Enter phone number"
                          :class="{ 'is-invalid': submitted && $v.form.phoneNumber.$error }"
                        />
                        <div v-if="submitted && $v.form.phoneNumber.$error" class="invalid-feedback">
                          <span v-if="!$v.form.phoneNumber.required">This value is required.</span>
                          <span v-if="!$v.form.phoneNumber.numeric">This value should be a valid phone number.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="validationCustom02">Branch Email address</label>
                      <div>
                        <input
                          id="validationCustom03"
                          v-model="form.emailAddress"
                          type="email"
                          class="form-control"
                          placeholder="Enter address "
                          :class="{ 'is-invalid': submitted && $v.form.emailAddress.$error }"
                        />
                        <div v-if="submitted && $v.form.emailAddress.$error" class="invalid-feedback">
                          <span v-if="!$v.form.emailAddress.required">This value is required.</span>
                          <span v-if="!$v.form.emailAddress.numeric">This value should be a valid email address.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="validationCustom04">Physical Address</label>
                      <input
                        id="validationCustom03"
                        v-model="form.location"
                        type="text"
                        class="form-control"
                        placeholder="Physical Address"
                        :class="{ 'is-invalid': submitted && $v.form.location.$error }"
                      />
                      <div v-if="submitted && $v.form.location.$error" class="invalid-feedback">
                        <span v-if="!$v.form.location.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-4" v-if="!submitted">
                        <button class="btn btn-primary" type="submit">Add Branch</button>
                    </div>
                    <div class="col-md-6">
                      <div class="spinner" v-if="submitted">
                          <i class="ri-loader-line spin-icon"></i>
                        </div>
                    </div>
                  </div>
              </form>
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>